<template>
  <div>
    <el-card>
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item, index) in pics"
                          :key="index">
          <img :src="item.pic"
               :error="src"
               alt="" />
        </el-carousel-item>
      </el-carousel>

      <!-- 添加图片 -->
      <el-button type="primary"
                 @click="showAddDialogVisible">添加图片</el-button>
      <!-- 表格区域 -->
      <el-table :data="pics"
                style="width: 100%">
        <el-table-column prop="sort"
                         align="center"
                         label="排序"
                         width="180">
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="warning"
                       @click="showEditDialogVisible(scope.row)">编辑图片</el-button>
            <el-button type="danger"
                       @click="removePicById(scope.row.id)">删除图片</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加图片弹出框 -->
      <el-dialog title="添加图片"
                 :visible.sync="addDialogVisible"
                 width="50%"
                 @close="AddDialogClosed">
        <!-- 主要内容 -->
        <el-form :model="AddForm"
                 :rules="AddFormRules"
                 ref="AddFormRef"
                 label-width="110px">
          <el-form-item label="跳转链接"
                        prop="goodsId">
            <el-input v-model="AddForm.goodsId" placeholder="可填写商品ID/支付宝应用链接/小程序内部页面链接"></el-input>
          </el-form-item>
          <!-- 排序 -->
          <el-form-item label="排序"
                        prop="sort">
            <el-input v-model="AddForm.sort"></el-input>
          </el-form-item>
          <!-- 主页图片 -->
          <el-form-item label="主页图片"
                        prop="pic">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-preview="mainHandlePictureCardPreview"
                       :on-remove="mainHleRemove"
                       :headers="headerObj"
                       :file-list="fileList"
                       :on-success="mainHandleSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="picDialogVisible">
              <img width="100%"
                   :src="dialogImageUrl"
                   alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
        <!-- 底部 -->
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="AddPic">确 定</el-button>
          <el-button @click="addDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 编辑对话框 -->
      <el-dialog title="编辑图片"
                 :visible.sync="editDialogVisible"
                 width="50%"
                 @close="editDialogClosed">
        <!-- 主要内容 -->
        <el-form :model="editForm"
                 :rules="AddFormRules"
                 ref="editFormRef"
                 label-width="110px">
          <el-form-item label="跳转链接"
                        prop="goodsId">
            <el-input v-model="editForm.goodsId" placeholder="可填写商品ID/支付宝应用链接/小程序内部页面链接"></el-input>
          </el-form-item>
          <el-form-item label="图片排序"
                        prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <!-- 图片 -->
          <el-form-item label="主页图片"
                        prop="Pic">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-preview="mainHandlePictureCardPreview"
                       :on-remove="mainHleRemove"
                       :headers="headerObj"
                       :file-list="fileList"
                       :on-success="mainHandleSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="picDialogVisible">
              <img width="100%"
                   :src="dialogImageUrl"
                   alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
        <!-- 底部 -->
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="edictPic">确 定</el-button>
          <el-button @click="editDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 接收图片
      pics: [],
      // 默认图片
      src: ' this.src=" ' + require('../assets/img/bg.png') + ' " ',

      // 控制添加图片显示隐藏
      addDialogVisible: false,

      // 添加图片表单
      AddForm: {
        pic: '',
        goodsId: '',
        sort: '',
      },

      // 添加图片规则
      AddFormRules: {
        goodsId: [
          { required: false, message: '请输入图片目标路径', trigger: 'blur' },
        ],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        pic: [{ required: true, message: '请添加图片' }],
      },

      // 声明一个图片的地址
      uploadURL: '/api/file/uploadFile',

      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },

      //图片地址
      dialogImageUrl: null,
      // 控制图片的显示隐藏
      picDialogVisible: false,

      // 编辑表单内容
      editForm: {
        pic: '',
        goodsId: '',
        sort: '',
      },
      // 编辑对话框显示与隐藏
      editDialogVisible: false,
      // 编辑表单规则
      editFormRules: {},
      // 存储主键ID
      ID: {
        id: '',
      },
      fileList: []
    }
  },
  created () {
    // 商品列表
    // console.log("window.localStorage.getItem('token')", window.localStorage.getItem('token'))
    if (window.localStorage.getItem('token')) {
      this.QueryPics()
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    // onImgError() {
    //   console.log('图片404')
    // },

    // 查询主页图片
    QueryPics () {
      this.$api.QueryPic().then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求主页图片失败')
        }
        this.pics = res.data
      })
    },

    // 显示对话框
    showAddDialogVisible () {
      this.addDialogVisible = true
    },

    // 添加主页图片
    AddPic () {
      this.$refs.AddFormRef.validate((valid) => {
        if (!valid) return
        this.$api.AddPic(this.AddForm).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('添加主页图片失败')
          } else {
            this.$message.success('添加主页图片成功')
          }
          this.addDialogVisible = false
          // 刷新列表
          this.QueryPics()

        })
      })
    },

    // 图片预览
    mainHandlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.picDialogVisible = true
      console.log(file)
    },

    // 图片删除
    mainHleRemove (file, fileList) {
      console.log(file)
      this.AddForm.pic = null
      this.editForm.pic = null
      this.fileList = []
    },

    // 图片上传成功之后的操作
    mainHandleSuccess (res) {
      console.log(res)
      // this.addGoodsForm.mainPic = res.data
      this.AddForm.pic = res.data
      this.editForm.pic = res.data
      this.fileList = [{ url: res.data }]
    },

    // 监听添加关闭事件
    AddDialogClosed () {
      this.fileList = []
      this.$refs.AddFormRef.resetFields()
    },

    // 监听编辑关闭事件
    editDialogClosed () {
      this.fileList = []
    },

    // 显示编辑对话框
    showEditDialogVisible (row) {
      // console.log(row)
      this.editForm = row
      this.fileList = [{ url: row.pic }]
      this.editDialogVisible = true
    },

    // 编辑主页图片
    edictPic () {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return
        if (!this.editForm.pic) return this.$message.error('请上传首页大图')
        this.$api.UpdatePic(this.editForm).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('编辑主页图片失败')
          } else {
            this.$message.success('编辑主页图片成功')
          }
          // console.log(res)
          this.editDialogVisible = false
          this.QueryPics()
          // this.pics = res.data
        })
      })
    },

    // 删除主页图片
    async removePicById (id) {
      this.ID.id = id
      console.log(this.ID)
      // this.ID.push(id)
      // // 去重
      // this.ID = [...new Set(this.ID)]
      const confirmResult = await this.$confirm(
        '此操作将永久删除该图片, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      // console.log(confirmResult)
      // 如果用户确认删除就返回个 confirm
      // 不删除就返回cancel
      if (confirmResult !== 'confirm') {
        // 当页面关闭或者取消的时候清空数组
        // this.ID.splice(0, this.ID.length)
        // console.log(this.ID)
        return this.$message.info('取消了删除')
      }
      // 下面发起真正的删除请求
      this.$api.DeletePic(this.ID).then((res) => {
        // if (res.code !== 200) {
        //   this.$message.error('删除图片失败')
        // } else {
        //   this.$message.success('删除图片成功')
        // }
        // 刷新列表
        this.QueryPics()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
